export default function MQ(breakpoints) {
	let body = document.body;
	let mqTester, viewport = {};

	/*
	 * Append test element, add rules, compare and bind for further comparisons
	 */
	let setup = function() {
		// Create and add element to the footer
		mqTester = body.appendChild(document.createElement('div'));
		mqTester.setAttribute('id', 'media-queries-tester');
		// Create new stylesheet and add rules for the test
		let sheet = addStyleSheet();
		addRules(sheet);
		// Store variables for comparison
		getViewportIndex();

		bind();
	};

	/*
	 * Bind resize to test viewport again
	 */
	let bind = function() {
		window.addEventListener('resize', function(){
			getViewportIndex();
		}, false);
	};

	/*
	 * Create and append a new stylesheet to the page
	 * From David Walsh blog - see https://davidwalsh.name/add-rules-stylesheets
	 */
	let addStyleSheet = function() {
		let style = document.createElement('style');
		style.appendChild(document.createTextNode(''));
		document.head.appendChild(style);
		return style.sheet;
	};

	/*
	 * Add rules to a stylesheet
	 * See MDN: https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleSheet/insertRule
	 */
	let addRules = function(sheet) {
		sheet.insertRule('#media-queries-tester {	opacity: 0;	position: fixed;	z-index: -1;	height: 0px; }', 0);

    breakpoints.forEach(function(breakpoint, i) {
      sheet.insertRule('@media only screen and (min-width: ' + (breakpoint).toString() + 'px) 	{	#media-queries-tester {	height: ' + (i+1).toString() + 'px;	}	}', (i+1));
    });
  };

	/*
	 * Get test result
	 */
	let getViewportIndex = function() {
    let current = document.getElementById('media-queries-tester').offsetHeight;

    breakpoints.forEach(function(breakpoint, i) {
      viewport['over' + breakpoint.toString()] = (current > i);
    });
	};

	setup();

	return viewport;
}
