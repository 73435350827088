import $ from "jquery";
import ScrollReveal from 'scrollreveal'

export function archive() {

  var winW = $(window).width();

  $(window).resize(function () {
    winW = $(window).width();
  } )

  var timer = null;

  function is_touch_device() {
    try {
      document.createEvent("TouchEvent");
      return true;
    } catch (e) {
      return false;
    }
  }

  if( !is_touch_device() ){
    $('.project a').hover(function (event) {

     var preview = $(this).parent().next();
     // var imgW = preview.width();
     preview.css({ visibility: "hidden", display: "block" });
     var imgW = preview.first().width();
     var imgH = preview.first().height();
     preview.css({ position: "", visibility: "", display: "" });

     var maxW = winW - imgW;
     var ranX = Math.floor(Math.random() * maxW);
     var ranY = Math.floor(Math.random() * imgH/2) - imgH/2;

     // check if image non si sovrappone
     var parentOffset = $(this).parent().offset();
     var relX = event.pageX - parentOffset.left;
     var relXMax = relX + 20;
     var relXMin = relX - 20;

     while (ranX + imgW > relXMin && ranX < relXMax) {
       ranX = Math.floor(Math.random() * maxW);
     }

     var parentPosition = $(this).parent().parent().offset();
     var parentTop = parentPosition.top;

     ranY += parentTop;

     preview.css({ 'display' : 'block', 'left' : ranX , 'top' : ranY });

     // timer = setTimeout(function() {
     //   preview.css({ 'visibility' : 'visible' });
     // }, 500 )
     preview.css({ 'visibility' : 'visible' });

    }, function () {
      clearTimeout(timer);
      var preview = $(this).parent().next();
      preview.css({ 'display' : 'none', 'visibility' : 'hidden' });
    } );
  }


  /* Auto-scroll */

  var timeout = null;

  var scrollSpeed = 30;
  var timeoutInterval = 100;
  var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if(is_safari){
    timeoutInterval = 1000;
  }
  var scrollSpace = $(document).height() - $(document).scrollTop();
  var scrollTime = scrollSpace * scrollSpeed;

  $("html, body").animate({ scrollTop: $(document).height() }, scrollTime, 'linear');

  $(window).on('mousewheel wheel DOMMouseScroll', function(event) {
    let main = $('main[data-barba-namespace="template-archive"]')
    if (main.length > 0){
      clearTimeout(timeout);
      $("html, body").stop();
      timeout = setTimeout(function() {
        scrollSpace = $(document).height() - $(document).scrollTop();
        scrollTime = scrollSpace * scrollSpeed;
        $("html, body").animate({ scrollTop: $(document).height() }, scrollTime, 'linear');
      }, timeoutInterval);
    }
  } )

  $(window).on('touchmove', function() {
    let main = $('main[data-barba-namespace="template-archive"]')
    if (main.length > 0){
      clearTimeout(timeout);
      $("html, body").stop();
      timeout = setTimeout(function() {
        scrollSpace = $(document).height() - $(document).scrollTop();
        scrollTime = scrollSpace * scrollSpeed;
        $("html, body").animate({ scrollTop: $(document).height() }, scrollTime, 'linear');
      }, timeoutInterval);
    }
  } )

  console.log("ARCHIVE!");
}
