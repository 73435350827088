import $ from "jquery";
var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin( $ );

export function home() {

  $( ".homeCarousel" ).hover(
    function() {
      $( this ).parent().append( $( "<span class='tooltip'>next</span>" ) );
      if($('.homeCarousel').children(":last").css('opacity') == 1) {
        // $('span.tooltip').html('all works');
        $('span.tooltip').html('next');
      }
      $( this ).mousemove(function(e) {
        $('span.tooltip').css({
          top: 0,
          left: 0,
          transform: `translate3d(${e.pageX - 10}px, ${e.pageY - 10}px, 0)`
        }).css({'display':'block', 'color':'#fff', 'position':'absolute', 'z-index':'9999999', 'pointer-events':'none', 'mix-blend-mode':'difference'});
      })
    }, function() {
      $( this ).parent().find( "span:last" ).remove();
  })

  $(document).ready( function () {
    if($('.homeCarousel').children(":first").data('menu-light') == 1){
      $('header').css({ 'color' : '#fff' })
      $('footer').css({ 'color' : '#fff' })
      $('.homeCarousel').addClass('white')
    } else {
      $('header').css({ 'color' : 'inherit' })
      $('footer').css({ 'color' : 'inherit' })
      $('.homeCarousel').removeClass('white')
    }
  } );

  $('.homeCarousel').on('click', function () {
    $(this).children().each( function () {
      if($('.homeCarousel').children(":last").css('opacity') == 1) {
        $('.homeCarousel').fadeOut(200, function(){
          window.location.href = "/works";
        });
        return;
        $('.homeCarousel').children().css({ 'opacity' : '0' });
      }
      if($(this).css('opacity') == 0){
        if($(this).data('menu-light') == 1){
          $('header').css({ 'color' : '#fff' })
          $('footer').css({ 'color' : '#fff' })
          $('.homeCarousel').addClass('white')
        } else {
          $('header').css({ 'color' : 'inherit' })
          $('footer').css({ 'color' : 'inherit' })
          $('.homeCarousel').removeClass('white')
        }
        $(this).css({ 'opacity' : 1 });
        return false;
      }
    } )
    if($('.homeCarousel').children(":last").css('opacity') == 1) {
      // $('span.tooltip').html('all works');
      $('span.tooltip').html('next');
    }
  } );

  $('.homeCarousel').imagesLoaded( function() {
    $('.homeCarousel').children(":first").css({ 'opacity' : '1' });
    if($('.homeCarousel').children(":first").data('menu-light') == 1){
      $('.intro').css({ 'color' : '#fff' })
    } else {
      $('intro').css({ 'color' : 'inherit' })
    }
    setTimeout(function(){
      $('.intro').addClass('active');
      $('header, footer').addClass('active');
    }, 1000);
  })

  console.log("HOMEPAGE!");
}
