import $ from "jquery";
import Cookies from "js-cookie";

export default function GDPRElement(wrapper) {
  const $alert = $(wrapper);
  const $optIn = $(".cookie-alert span");

  const setup = () => {
    bind();
  };

  const bind = () => {
    $optIn.one("click", optIn);
  };

  const optIn = e => {
    if (e) e.preventDefault();
    Cookies.set("gdpr-ga", "opt-in", { expires: 60 });
    window.location.reload();
  };

  setup();

  return {};
}
