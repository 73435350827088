// Do not touch: used by webpack to bundle CSS
import styles from "./../css/main.css";

// Libraries
import "./utils/modernizr-custom-3.5.0";
import $ from "jquery";
//import Headroom from 'headroom.js'

// Detects
import MQ from "./utils/mq";

// Views
import { home } from "./views/home";
import { about } from "./views/about";
import { archive } from "./views/archive";
import { single } from "./views/single";

// Polyfills
import objectFitImages from "object-fit-images";

// Other utils
import GDPRElement from "./modules/gdpr/gdpr";
import "./modules/gdpr/ga";
import barba from "@barba/core";

// Apply polyfills
if (!Modernizr.objectfit || !Modernizr["object-fit"]) {
  objectFitImages();
}

// Initialize GDPR module
GDPRElement(".gdpr-ga-state");
GDPRElement(".gdpr-ga-alert");

// Initialize media queries detect
const mediaQueriesBreakpoints = [
  320,
  480,
  586,
  640,
  768,
  840,
  960,
  1024,
  1280,
  1400,
  1600,
  1920
];
const mq = MQ(mediaQueriesBreakpoints);

// To test media queries on resize ...
$(window).on("resize", onResize);

function onResize() {
  if (mq.over320) {
    console.log("over 320 pixels");
  }

  if (mq.over480) {
    console.log("over 480 pixels");
  }
}

$("main").css({ opacity: "1" });

// Specific routines per template
let templateName = $("main").data("barba-namespace");

/* Cookies alert hover */
$('.cookie-alert').hover(
  function () {
    $(this).find('.default').css({ 'opacity':'0' });
    $(this).find('.hover').css({ 'opacity':'1', 'z-index' : '' });
    $('.gdpr-ga-alert').addClass('show');
  },
  function () {
    $(this).find('.default').css({ 'opacity':'1' });
    $(this).find('.hover').css({ 'opacity':'0', 'z-index' : '' });
    $('.gdpr-ga-alert').removeClass('show');
  }
);

if (templateName === "template-home") {
  home();
} else if (templateName === "template-about") {
  about();
} else if (templateName === "template-archive") {
  archive();
} else if (templateName === "template-basic") {
  // do nothing
} else if (templateName === "single") {
  single();
}

// defines a global hook
barba.hooks.after(data => {
  window.scrollTo(0, 0);

  setTimeout(function() {
    $("main").css({ opacity: "1" });
  }, 500);

  // reset on current link click action
  var links = document.querySelectorAll("a[href]");
  var cbk = function(e) {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener("click", cbk);
  }

  $("html, body").stop();
  // detect new template
  $("header").css({ color: "inherit" });
  $("footer").css({ color: "inherit" });
  templateName = $("main").data("barba-namespace");
  if (templateName === "template-home") {
    home();
  } else if (templateName === "template-about") {
    about();
  } else if (templateName === "template-archive") {
    archive();
  } else if (templateName === "template-basic") {
    // do nothing
  } else if (templateName === "single") {
    single();
  }
});

barba.hooks.leave(data => {
  console.log("bye");
  $("main").css({ opacity: "inherit" });
});

// ajax barba
// barba.init();
