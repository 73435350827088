/*
===
Modules import
===
*/
import $ from "jquery";
import MQ from "./../utils/mq";
import ScrollReveal from 'scrollreveal'
var imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin($);

/*
===
View function
===
*/

export function single() {

  /*
  ===
  Funzione controlli video
  ===
  */

  $( ".Video-overlay" ).hover(
    function() {
      $( this ).parent().append( $( "<span class='tooltip'>unmute</span>" ) );
      $( this ).mousemove(function(e) {
        $('span.tooltip').css({
          top: 0,
          left: 0,
          transform: `translate3d(${e.pageX  - $(this).parent().offset().left - 30}px, ${e.pageY - $(this).parent().offset().top - 10}px, 0)`
        }).css({'display':'block', 'color':'#fff', 'position':'absolute', 'z-index':'9999999', 'pointer-events':'none', 'mix-blend-mode':'difference'});
      })
    }, function() {
      $( this ).parent().find( "span:last" ).remove();
  })

  $('.Video-overlay').on('click', function() {
    $(this).parent().find('span.tooltip').hide();
    $(this).parent().find('video').prop('muted', false);
    $(this).parent().find('video').prop('controls', true);
    $(this).parent().find('video').prop('loop', false);
    $(this).remove()
  } )

  /*
  ===
  Funzione dello scroll a fine pagina
  ===
  */

  $(document).on('scroll', function() {
      if($(this).scrollTop()>=$('.nav').position().top){
        let translation = $(document).scrollTop() - $('.nav').position().top;
        $('.photoshoot .title').css({ 'transform' : 'translateY(-'+translation+'px)' });
      } else {
        $('.photoshoot .title').css({ 'transform' : 'translateY(0px)' });
      }
  })

  /*
  ===
  Setup del titolo progetto
  ===
  */

  const mediaQueriesBreakpoints = [
    320,
    480,
    586,
    640,
    768,
    840,
    960,
    1024,
    1280,
    1400,
    1600,
    1920
  ];
  const mq = MQ(mediaQueriesBreakpoints);

  $(window).on("resize", onResize);
  if (mq.over640) {
    var mainTop = parseInt($('.main').css("marginTop").replace('px', '')) + parseInt($('.main').css("paddingTop").replace('px', ''));
    $('h1.title').css({ 'position' : 'fixed', 'margin' : 'auto', 'left' : '0', 'right' : '0', 'top' : mainTop+'px' });
    $('.photoshoot').css({ 'padding-top' : $('h1.title').outerHeight() });
  }

  function onResize() {
    if (mq.over640) {
      let mainTop = parseInt($('.main').css("marginTop").replace('px', '')) + parseInt($('.main').css("paddingTop").replace('px', ''));
      $('h1.title').css({ 'position' : 'fixed', 'margin' : 'auto', 'left' : '0', 'right' : '0', 'top' : mainTop+'px' });
      $('.photoshoot').css({ 'padding-top' : $('h1.title').outerHeight() });
    } else {
      $('h1.title').css({ 'position' : '', 'margin' : '', 'left' : '', 'right' : '', 'top' : '' });
      $('.photoshoot').css({ 'padding-top' : '' });
    }
  }

  /*
  ===
  Setup immagini
  ===
  */

  $('#contents').imagesLoaded()
  .progress( function( instance, image ) {
    if ( image.img.clientWidth > image.img.clientHeight ){
      image.img.classList.add('landscape');
    } else if ( image.img.clientWidth < image.img.clientHeight ) {
      image.img.classList.add('portrait');
    } else {
      image.img.classList.add('squared');
    }
  });

}
