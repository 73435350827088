import $ from "jquery";
import ScrollReveal from 'scrollreveal'

export function about() {

  // ScrollReveal().reveal('.about p', {
  //   delay: 0,
  //   distance: '30px',
  //   duration: 400,
  //   easing: 'cubic-bezier(0.5, 0, 0, 0.5)',
  //   interval: 0,
  //   opacity: 1,
  //   origin: 'bottom',
  //   rotate: {
  //     x: 0,
  //     y: 0,
  //     z: 0,
  //   },
  //   scale: 1,
  //   cleanup: false,
  //   container: document.documentElement,
  //   desktop: true,
  //   mobile: true,
  //   reset: true,
  //   useDelay: 'always',
  //   viewFactor: 0.0,
  //   viewOffset: {
  //       top: 0,
  //       right: 0,
  //       bottom: 0,
  //       left: 0,
  //   },
  //   afterReset: function (el) {},
  //   afterReveal: function (el) {},
  //   beforeReset: function (el) {},
  //   beforeReveal: function (el) {},
  // });

  console.log("ABOUT!");
}
